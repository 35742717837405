// TODO: Write documentation for setup
// Import all resources
import "ol/ol.css";
import "./index.css";
import "./scripts/widgets.js";
import "./intro/intro.js";
import { scheme1, scheme2, scheme3, CONTACTLINK } from "./scripts/hardcoded.js";
import { streetLayer, topoLayer, satLayer, tradeoffs } from "./scripts/hardcoded.js";
import { plotHist } from "./scripts/plot.js";
import { makeCompass } from "./scripts/compass";
import Map from "ol/Map";
import View from "ol/View";
import VectorLayer from "ol/layer/Vector";
import TileLayer from "ol/layer/Tile";
import VectorSource from "ol/source/Vector";
import Overlay from "ol/Overlay";
import { fromLonLat, transformExtent } from "ol/proj";
import { Style, Fill, Stroke } from "ol/style";
import { fromExtent } from "ol/geom/Polygon";
import Feature from "ol/Feature";
import FullScreen from "ol/control/FullScreen";
import Zoom from "ol/control/Zoom";
import GeoJSON from "ol/format/GeoJSON";
import Select from "ol/interaction/Select";
import { extend, containsExtent, getCenter } from "ol/extent";
import { toLonLat } from "ol/proj";
import { defaults as defaultControls, Control, ScaleLine } from "ol/control";
import TileArcGISRest from "ol/source/TileArcGISRest";

// Set up accordion on the left side
$("#input-accordion").accordion({
  heightStyle: "content",
});

// Set global parameters of view state
window.selectionType = "Connecticut";
window.townSelection = "";
window.cogSelection = "";
window.customSelection = "";

// Hack to fix a placement bug in OpenLayers
var fixSizeBug = function () {
  setTimeout(function () {
    map.updateSize();
  }, 100);
  setTimeout(function () {
    map.updateSize();
  }, 200);
};

// Create a capitalization function for metadata manipulation
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

// Insert compass for map rotation. This comes from compass.js
var compassElement = $("#compass")[0];
makeCompass(compassElement, function (theta) {
  map.getView().setRotation(-theta);
});

// Establish a popup container for loading information on individual cells
var container = $("#popup");

var popupOverlay = new Overlay({
  element: container[0],
  autoPan: true,
  autoPanAnimation: {
    duration: 250,
  },
});

function closePopup() {
  popupOverlay.setPosition(undefined);
  select.getFeatures().clear();
  return false;
}

// Create variables for handling selection box behavior
var jurisdiction_picker = $("#juris-picker");
var ind_layer_picker = $("#independent-layer-picker");
var out_layer_picker = $("#output-layer-picker");
var esac_layer_picker = $("#esac-layer-picker");
var overlay_picker = $("#overlay-picker");

// Metadata about layers and jurisdictions, allowing dynamic switching
$.getJSON("./metadata.json", function (data) {
  fixSizeBug();
  let layers = data.layers;
  let towns = data.towns;
  let cogs = data.cogs;
  let overlays = data.overlays;
  // Add a line to a selection box
  function makeOption(name, code, type) {
    return $("<option/>", {
      text: name,
      value: code,
      data: {
        type: type,
      },
    });
  }

  // Generate layers option box
  var sortedLayerKeys = Array(data.layerList.length);
  Object.keys(layers).forEach(function (key) {
    let nickname = layers[key].nickname;
    function findMatch(element) {
      return element == nickname;
    }
    let index = data.layerList.findIndex(findMatch);
    sortedLayerKeys[index] = key;
  });
  sortedLayerKeys.forEach(function (key) {
    let layerCode = key;
    let layerName = layers[key].nickname;
    let layerType = layers[key].layerType;
    if (layerType == "ind") {
      var option = makeOption(layerName, layerCode, "layer");
      ind_layer_picker.append(option);
    } else if (layerType == "out") {
      var option = makeOption(layerName, layerCode, "layer");
      out_layer_picker.append(option);
    } else if (layerType == "esac") {
      var option = makeOption(layerName, layerCode, "layer");
      esac_layer_picker.append(option);
    }
  });

  // Ensure that the layer boxes correctly select on startup
  window.independentLayer = ind_layer_picker.children().first().val();
  window.outLayer = out_layer_picker.children().first().val();
  window.esacLayer = esac_layer_picker.children().first().val();

  // Manually add Connecticut as a jurisdiction type
  jurisdiction_picker
    .append(
      makeOption("Connecticut", "a", "Connecticut").attr("selected", "selected")
    )
    .append(makeOption("Custom Extent", "a", "Custom"));

  // Add town options
  Object.keys(towns).forEach(function (key) {
    let townCode = key;
    let townName = towns[key].nickname;
    let option = makeOption(townName, townCode, "Town");
    jurisdiction_picker.append(option);
  });

  // Add COG options
  Object.keys(cogs).forEach(function (key) {
    let cogCode = key;
    let cogName = cogs[key].nickname;
    let option = makeOption(cogName, cogCode, "COG");
    jurisdiction_picker.append(option);
  });

  // Add overlay options
  overlay_picker.append(makeOption("None", "none", null));
  Object.keys(overlays).forEach(function (key) {
    let url = overlays[key].url;
    let layer = overlays[key].layer;
    let nickname = overlays[key].nickname;
    let option = makeOption(nickname, url, layer);
    overlay_picker.append(option);
  });

  // Define jurisdiction selection function
  jurisdiction_picker.combobox({
    label: "Select a jurisdiction",
    select: function (event, ui) {
      let choice = ui.value;
      let type = ui.type;
      if (type == "Custom") {
        window.selectionType = "Custom";
        selectedBounds.show();
      } else {
        selectedBounds.hide();
        if (type == "Connecticut") {
          window.selectionType = "Connecticut";
        } else if (type == "Town") {
          window.selectionType = "Town";
          window.townSelection = choice;
        } else if (type == "COG") {
          window.selectionType = "COG";
          window.cogSelection = choice;
        }
      }
      updateLayer();
      zoomToVectorExtent();
    },
  });

  // Convert select box into autocomplete combobox
  ind_layer_picker.combobox({
    label: "Select a layer",
    select: function (event, ui) {
      window.independentLayer = ui.item.value;
      window.currentLayer = window.independentLayer;
      updateLayer();
    },
  });
  out_layer_picker.combobox({
    label: "Select an output layer",
    select: function (event, ui) {
      window.outLayer = ui.item.value;
      window.currentLayer = window.outLayer;
      updateLayer();
    },
  });
  esac_layer_picker.combobox({
    label: "Select an exposure, sensitivity & adaptive capacity layer",
    select: function (event, ui) {
      window.esacLayer = ui.item.value;
      window.currentLayer = window.esacLayer;
      updateLayer();
    },
  });
  overlay_picker.combobox({
    label: "Select an overlay option",
    select: function (event, ui) {
      let url = ui.value;
      let layer = ui.type;
      if (url == "none") {
        map.removeLayer(window.overlayLayer);
      } else {
        map.removeLayer(window.overlayLayer);
        window.overlayLayer = new TileLayer({
          source: new TileArcGISRest({
            params: {
              LAYERS: layer,
            },
            url: url,
          }),
          opacity: 0.8,
        });
        map.addLayer(window.overlayLayer);
      }
    },
  });

  // Expose necessary data and update display
  window.metadata = data;
  window.currentLayer = window.independentLayer;
  updateLayer();
});

// This is the core update function that is called after every change to the map
function updateLayer(resetStyle = false) {
  if (window.metadata) {
    vectorLayer.getSource().changed();
    closePopup();
    calculateActiveVectorProperties();
    displayLayer(window.currentLayer);
    if (resetStyle) {
      styleCache = {
        clear: new Style({
          fill: new Fill({
            color: window.colorScheme.clear,
          }),
          stroke: null,
        }),
      };
    }
  }
}

// Update the display layer explanation
function displayLayer(val) {
  let content = $("#layer-explanation");
  content.html("");
  let downloads = $("#downloads");
  downloads.html("");
  if (window.displayMode == "single") {
    let layerName = window.metadata.layers[val].nickname;
    let abstract = window.metadata.layers[val].abstract;
    let link = window.metadata.layers[val].link;
    let metalink = window.metadata.layers[val].metalink;
    let shortName = window.metadata.layers[val].shortName;
    let zipFolder = window.metadata.layers[val].layerType === "ind" ? "contributor" :
      (window.metadata.layers[val].layerType === "out" ? "vulnerability" : "ESAC");
    let zipfile = "zipped_layers/" + zipFolder + "/" + shortName + ".zip";
    let rankTable = $("<table>");
    $.ajax({
      dataType: "json",
      url: "./tables/" + shortName + ".json",
      success: function (data) {
        let names = data.names;
        let ranks = data.table;
        let row = $("<tr>");
        names.forEach(function (name) {
          row.append($("<td>").text(name));
        });
        rankTable.append(row);
        ranks.forEach(function (rankRow) {
          let row = $("<tr>");
          names.forEach(function (name) {
            let val = rankRow[name];
            if (isNaN(val)) {
              row.append($("<td>").text(val));
            } else {
              row.append($("<td>").text(Number(val).toFixed(2)));
            }
          });
          rankTable.append(row);
        });
      },
    });
    content.append($("<h2>").text(layerName));
    content.append($("<p>").text(abstract));
    var linkList = $("<ul>");
    if (link != "") {
      linkList.append(
        $("<li>").append(
          $("<a>")
            .text("Source Link")
            .attr("href", link)
            .attr("target", 'target="_blank"')
        )
      );
    }
    if (metalink != "") {
      linkList.append(
        $("<li>").append(
          $("<a>")
            .text("Download Metadata")
            .attr("href", metalink)
            .attr("target", 'target="_blank"')
        )
      );
    }

    if (metalink != "") {
      linkList.append(
        $("<li>").append(
          $("<a>")
            .text("Download GIS Layer")
            .attr("href", zipfile)
            .attr("target", 'target="_blank"')
        )
      );
    }
    content.append(rankTable);
  } else {
    content.append($("<h2>").text(window.currentTradeoffSpecification.name));
    content.append($("<p>").text(window.currentTradeoffSpecification.description));
  } 
  content.append($("<h3>").text("Selected Area Statistics"));
  let plot = $("<div>").addClass("side-plot");
  content.append(plot);
  plotHist(window.layerProps.values, ".side-plot", colorRankInterpolate);
  let table = $("<table>");
  function addTableRow(thing, val) {
    let row = $("<tr>");
    row.append($("<td>").text(thing));
    row.append($("<td>").text(val));
    table.append(row);
  }
  addTableRow("Statistic", "Value");
  if (window.layerProps.dataCount != 0) {
    addTableRow("Mean Rank", window.layerProps.mean);
    addTableRow("Standard Deviation", window.layerProps.std);
    addTableRow("Min Rank", window.layerProps.min);
    addTableRow("Max Rank", window.layerProps.max);
  }
  addTableRow("Data Acreage", window.layerProps.dataCount);
  addTableRow("Total Acreage", window.layerProps.totalCount);

  content.append($("<p>").html(table));
  downloads.append($("<h2>").text("Downloads"));
  downloads.append(linkList);
  downloads.append(
    $("<p>").html(
      "Sign up for the CIRCA Announcements list for the latest CIRCA research, tools, grants, and engagement opportunities and Resilience Roundup for a monthly clips service of the local, state, and national news on resilience as well as announcements on CIRCA and non-CIRCA events and resources."
    )
  );
  downloads.append(
    $("<form>")
      .attr("action", CONTACTLINK)
      .attr("method", "get")
      .attr("target", "_blank")
      .attr("class", "centered")
      .append($("<button>").text("Sign Up Now").attr("type", "submit"))
  );
}

// Function to change colors of map
function setColors(scheme, transparency) {
  var outScheme = {
    clear: [0, 0, 0, 0],
    transparent: [255, 255, 255, transparency * 0.3],
    one: scheme.one.slice(0, 3),
    two: scheme.two.slice(0, 3),
    three: scheme.three.slice(0, 3),
    four: scheme.four.slice(0, 3),
    five: scheme.five.slice(0, 3),
    name: scheme.name,
    code: scheme.code,
    transparency: transparency,
  };
  outScheme.one.push(transparency);
  outScheme.two.push(transparency);
  outScheme.three.push(transparency);
  outScheme.four.push(transparency);
  outScheme.five.push(transparency);

  window.colorScheme = outScheme;
  window.dispatchEvent(new Event("resize")); // Fix color bar issue on Chrome
  updateLayer(true); // Redraw map with new colors
}

// Set default color schema, using shcema from the hardcoded file
setColors(scheme1, 0.7);

// Create the color selection box
$("#color-box").colorbox({
  data: [scheme1, scheme2, scheme3],
  callback: setColors,
});
$("#trans-slider").prop_slider({
  left: "Transparent",
  right: "Solid",
  max: 100,
  callback: function (value) {
    setColors(window.colorScheme, value);
    updateLayer(true);
  },
});

// Code to calculate interpolated color values
function colorRankInterpolate(rank) {
  if (rank < 0.8) {
    return window.colorScheme.transparent;
  } else if (rank < 1) {
    return window.colorScheme.one;
  } else if (rank < 2) {
    var prop = rank - 1;
    return window.colorScheme.one.map(
      (e, i) => (1 - prop) * e + prop * window.colorScheme.two[i]
    );
  } else if (rank < 3) {
    var prop = rank - 2;
    return window.colorScheme.two.map(
      (e, i) => (1 - prop) * e + prop * window.colorScheme.three[i]
    );
  } else if (rank < 4) {
    var prop = rank - 3;
    return window.colorScheme.three.map(
      (e, i) => (1 - prop) * e + prop * window.colorScheme.four[i]
    );
  } else if (rank < 5) {
    var prop = rank - 4;
    return window.colorScheme.four.map(
      (e, i) => (1 - prop) * e + prop * window.colorScheme.five[i]
    );
  } else {
    return window.colorScheme.five;
  }
}

// Determine if data should be shown, given a jurisdiction selection
function shouldShowFeature(feature) {
  if (window.selectionType == "Connecticut") {
    return true;
  } else if (window.selectionType == "Custom") {
    let featExtent = feature.getGeometry().getExtent();
    let boundingExtent = selectedBounds.getExtent();
    return containsExtent(boundingExtent, featExtent);
  } else if (
    window.selectionType == "Town" &&
    window.townSelection == feature.getProperties().t
  ) {
    return true;
  } else if (
    window.selectionType == "COG" &&
    window.cogSelection == feature.getProperties().c
  ) {
    return true;
  } else {
    return false;
  }
}

// Calculate properties to be used in the 'Layer Info' tab
function calculateActiveVectorProperties() {
  var noZerosRankValues = Array();
  var rankValues = Array();
  var firstFeature = true;
  var featExtent = [];

  vectorLayer.getSource().forEachFeature(function (feature) {
    if (shouldShowFeature(feature)) {
      let rank = getRankValue(feature);
      // counts[Math.round(rank)] += 1;
      rankValues.push(rank);
      if (rank > 0) {
        noZerosRankValues.push(rank);
      }
      if (firstFeature) {
        featExtent = feature.getGeometry().getExtent();
        firstFeature = false;
      } else {
        extend(featExtent, feature.getGeometry().getExtent());
      }
    }
  });
  if (noZerosRankValues.length != 0) {
    let min = 10;
    let max = 0;
    let sum = 0;
    for (var i = 0; i < noZerosRankValues.length; i++) {
      if (noZerosRankValues[i] < min) {
        min = noZerosRankValues[i];
      }
      if (noZerosRankValues[i] > max) {
        max = noZerosRankValues[i];
      }
      sum = sum + noZerosRankValues[i];
    }
    let mean = sum / noZerosRankValues.length;
    let squareSum = 0;
    for (var i = 0; i < noZerosRankValues.length; i++) {
      squareSum = squareSum + (noZerosRankValues[i] - mean) ** 2;
    }
    let std = Math.sqrt(squareSum / noZerosRankValues.length);

    window.layerProps = {
      totalCount: rankValues.length,
      dataCount: noZerosRankValues.length,
      min: min.toFixed(2),
      max: max.toFixed(2),
      mean: mean.toFixed(2),
      std: std.toFixed(2),
      values: rankValues,
    };
  } else {
    window.layerProps = {
      totalCount: rankValues.length,
      dataCount: noZerosRankValues.length,
      values: rankValues,
    };
  }
  window.activeExtent = featExtent;
}

// Fly to vector extent
function zoomToVectorExtent() {
  calculateActiveVectorProperties();
  map.getView().fit(window.activeExtent, { duration: 1000 });
}

// Calculate rank value based on current settings
function getRankValue(feature) {
  if (window.displayMode == "single") {
    var rank = feature.getProperties()[window.currentLayer];
    return rank;
  } else {
    let rankValues = feature.getProperties();
    let context = window.currentTradeoffContext;
    for (let field_name in window.currentTradeoffLayerKeys) {
      context[field_name] =
        rankValues[window.currentTradeoffLayerKeys[field_name]];
    }
    return window.currentTradeoffSpecification.callback(context);
  }
}

// Cache styles to speed up rendering
var styleCache = {
  clear: new Style({
    fill: new Fill({
      color: window.colorScheme.clear,
    }),
    stroke: null,
  }),
};

// Convert interpolated colors to a Openlayers style
function colorStyle(feature) {
  if (feature) {
    let rank = getRankValue(feature);
    let inBounds = shouldShowFeature(feature);
    if (inBounds) {
      var style = styleCache[rank];
      if (style != null) {
        return style;
      }
      var color = colorRankInterpolate(rank);
    } else {
      return styleCache["clear"];
    }
    if (color == window.colorScheme.transparent) {
      var stroke = null;
    } else {
      var stroke = new Stroke({
        width: 2,
        color: color,
      });
    }
    var style = (styleCache[rank] = new Style({
      fill: new Fill({
        color: color,
      }),
      stroke: stroke,
    }));
    return style;
  }
}

// Grab compressed vector data
var vectorSource = new VectorSource({
  format: new GeoJSON(),
  url: "./data.json",
});

// Create a layer from the vector data
var vectorLayer = new VectorLayer({
  source: vectorSource,
  renderMode: "image",
  style: colorStyle,
});

vectorSource.once("change", function (e) {
  if (vectorSource.getState() === "ready") {
    $(".loading").remove();
  }
});

// Setting up the map
var map = new Map({
  layers: [vectorLayer],
  controls: [
    new Zoom(),
    // Modify fullscreen button to include sidebars
    new FullScreen({
      source: $("#viewer")[0],
    }),
    new ScaleLine({
      units: "us",
      bar: true,
      steps: 4,
      text: false,
      minWidth: 120,
    }),
  ],
  target: "map",
  overlays: [popupOverlay],
  view: new View({
    // center: form
    center: fromLonLat([-72.8, 41.3]),
    zoom: 9,
    // add zoom and extent limitations here later
  }),
});

// Object that handles the selection of areas of interest by the user.
// It allows selecting from known geographical features, corner
// dragging, and and manual coordinate entry. It integrates with other
// map specific features.
var selectedBounds = {
  minX: null,
  minY: null,
  maxX: null,
  maxY: null,
  initialized: false,
  enabled: false,

  // Function to zoom to selected bounds
  zoomTo: function () {
    map.getView().fit(this.getExtent(), {
      duration: 2000,
    });
  },

  // Returns selected extent in list of WGS84 coordinates. Only use if initialization has occured
  getExtent: function () {
    return [this.minX, this.minY, this.maxX, this.maxY];
  },

  // Returns extent in list of decimal degrees
  getLatLongExtent: function () {
    return transformExtent(this.getExtent(), "EPSG:3857", "EPSG:4326");
  },

  // Sets internal extent values after validation
  setExtent: function (extentInput) {
    var [minX, minY, maxX, maxY] = this.getExtent();
    // Check for null values
    if (extentInput[0] != null) {
      minX = extentInput[0];
    }
    if (extentInput[1] != null) {
      minY = extentInput[1];
    }
    if (extentInput[2] != null) {
      maxX = extentInput[2];
    }
    if (extentInput[3] != null) {
      maxY = extentInput[3];
    }
    // Check for inverted values
    if (minX > maxX) {
      let dummy = minX;
      minX = maxX;
      maxX = dummy;
    }
    if (minY > maxY) {
      let dummy = minY;
      minY = maxY;
      maxY = dummy;
    }
    this.minX = minX;
    this.minY = minY;
    this.maxX = maxX;
    this.maxY = maxY;
  },

  // Set extent in decimal LatLong format
  setLatLongExtent: function (extentInput) {
    this.setExtent(transformExtent(extentInput, "EPSG:4326", "EPSG:3857"));
  },

  // Toggle visibility of the selectedBounds
  toggle: function () {
    if (this.enabled) {
      this.hide();
    } else {
      this.show();
    }
  },

  // TODO: put initialized gating in the show() function
  // Prep actions for selectedBound's first show()
  _initialize: function () {
    if (!this.initialized) {
      // Set initialization status
      this.initialized = true;

      // Add html for overlay corners. It's a little hacky,
      // but it works well without wasted space
      $("#viewer").append(
        ' \
                <div id="neCorner" class="boundingCorners"></div> \
                <div id="nCorner" class="boundingCorners"></div> \
                <div id="nwCorner" class="boundingCorners"></div> \
                <div id="sCorner" class="boundingCorners"></div> \
                <div id="seCorner" class="boundingCorners"></div> \
                <div id="eCorner" class="boundingCorners"></div> \
                <div id="swCorner" class="boundingCorners"></div> \
                <div id="wCorner" class="boundingCorners"></div> \
            '
      );

      // Register update events for clicking on the corners
      this._registerCornerDrag("neCorner", 2, 3);
      this._registerCornerDrag("nCorner", undefined, 3);
      this._registerCornerDrag("nwCorner", 0, 3);
      this._registerCornerDrag("sCorner", undefined, 1);
      this._registerCornerDrag("seCorner", 2, 1);
      this._registerCornerDrag("eCorner", 2, undefined);
      this._registerCornerDrag("swCorner", 0, 1);
      this._registerCornerDrag("wCorner", 0, undefined);

      // Create overlays for adjustment knobs
      this.neCorner = new Overlay({
        element: document.getElementById("neCorner"),
        positioning: "center-center",
      });
      this.nCorner = new Overlay({
        element: document.getElementById("nCorner"),
        positioning: "center-center",
      });
      this.nwCorner = new Overlay({
        element: document.getElementById("nwCorner"),
        positioning: "center-center",
      });
      this.sCorner = new Overlay({
        element: document.getElementById("sCorner"),
        positioning: "center-center",
      });
      this.seCorner = new Overlay({
        element: document.getElementById("seCorner"),
        positioning: "center-center",
      });
      this.eCorner = new Overlay({
        element: document.getElementById("eCorner"),
        positioning: "center-center",
      });
      this.swCorner = new Overlay({
        element: document.getElementById("swCorner"),
        positioning: "center-center",
      });
      this.wCorner = new Overlay({
        element: document.getElementById("wCorner"),
        positioning: "center-center",
      });

      // Calculate a default extent based on current viewport if
      // no extent has been set
      if (this.minX == null) {
        let shrinkProp = 0.8;
        let shrinkBottom = 0.4;
        let viewExtent = map.getView().calculateExtent();

        let centerX = (viewExtent[0] + viewExtent[2]) * 0.5;
        let centerY = (viewExtent[1] + viewExtent[3]) * 0.5;
        let spanX = viewExtent[2] - viewExtent[0];
        let spanY = viewExtent[3] - viewExtent[1];

        this.minX = centerX - 0.5 * shrinkProp * spanX;
        this.minY = centerY - 0.5 * shrinkBottom * spanY;
        this.maxX = centerX + 0.5 * shrinkProp * spanX;
        this.maxY = centerY + 0.5 * shrinkProp * spanY;
      }
    }
  },

  hide: function () {
    if (this.initialized) {
      // handdle control panel modifications
      let extentBox = $("#extent-box-collapsible");
      extentBox.css({
        "max-height": "0px",
      });

      // remove corners
      this.neCorner.setPosition(undefined);
      this.nCorner.setPosition(undefined);
      this.nwCorner.setPosition(undefined);
      this.sCorner.setPosition(undefined);
      this.seCorner.setPosition(undefined);
      this.eCorner.setPosition(undefined);
      this.swCorner.setPosition(undefined);
      this.wCorner.setPosition(undefined);

      // remove square
      if (this.boxLayer != null) {
        map.removeLayer(this.boxLayer);
        this.boxLayer = undefined;
      }

      // set status state
      this.enabled = false;
    }
  },

  // Function to allow dragging of the box corners
  _registerCornerDrag: function (elemName, xIndex, yIndex) {
    document
      .getElementById(elemName)
      .addEventListener("mousedown", function (evt) {
        function move(evt) {
          let dragPoint = map.getEventCoordinate(evt);
          let newExtent = new Array(4);
          newExtent[xIndex] = dragPoint[0];
          newExtent[yIndex] = dragPoint[1];
          selectedBounds.setExtent(newExtent);
          selectedBounds.show();
        }

        function end(evt) {
          updateLayer();
          window.removeEventListener("mousemove", move);
          window.removeEventListener("mouseup", end);
        }
        window.addEventListener("mousemove", move);
        window.addEventListener("mouseup", end);
      });
  },

  // Display selectedBounds and prepare viewport fo interaction
  show: function () {
    // Handle control panel modifications
    let extentBox = $("#extent-box-collapsible");
    extentBox.css({
      "max-height": "100%",
    });

    // Initialize on on first show()
    // TODO: Move logic here
    this._initialize();

    // This is the inner bounding box.
    let extent = this.getExtent();

    // Set color scheme for box
    let innerStyle = new Style({
      stroke: new Stroke({
        width: 4,
        color: "#325F90", //[0, 60, 136, 1]
      }),
      fill: null,
    });

    let interiorArea = new fromExtent(extent);
    let interior = new Feature(interiorArea);
    interior.setStyle(innerStyle);

    // Remove any old vector layers and insert a new one
    if (this.boxLayer != null) {
      map.removeLayer(this.boxLayer);
    }
    this.boxLayer = new VectorLayer({
      source: new VectorSource({
        features: [interior],
      }),
    });

    // Update corner positions
    this.neCorner.setPosition([extent[2], extent[3]]);
    this.nCorner.setPosition([(extent[0] + extent[2]) * 0.5, extent[3]]);
    this.nwCorner.setPosition([extent[0], extent[3]]);
    this.sCorner.setPosition([(extent[0] + extent[2]) * 0.5, extent[1]]);
    this.seCorner.setPosition([extent[2], extent[1]]);
    this.eCorner.setPosition([extent[2], (extent[1] + extent[3]) * 0.5]);
    this.swCorner.setPosition([extent[0], extent[1]]);
    this.wCorner.setPosition([extent[0], (extent[1] + extent[3]) * 0.5]);

    // Display layers and overlays
    map.addLayer(this.boxLayer);

    map.updateSize();
    map.addOverlay(this.neCorner);
    map.addOverlay(this.nCorner);
    map.addOverlay(this.nwCorner);
    map.addOverlay(this.sCorner);
    map.addOverlay(this.seCorner);
    map.addOverlay(this.eCorner);
    map.addOverlay(this.swCorner);
    map.addOverlay(this.wCorner);

    // Update text inputs
    let longLatExtent = this.getLatLongExtent();
    $("#WLatLong").val(Number.parseFloat(longLatExtent[0]).toFixed(4));
    $("#SLatLong").val(Number.parseFloat(longLatExtent[1]).toFixed(4));
    $("#ELatLong").val(Number.parseFloat(longLatExtent[2]).toFixed(4));
    $("#NLatLong").val(Number.parseFloat(longLatExtent[3]).toFixed(4));

    // Change object status
    this.enabled = true;

    // Hack to fix a placement bug in OpenLayers
    fixSizeBug();
  },
};

// Fix layout bug on launch
fixSizeBug();

// Set up text input
var setInputLatLongVals = function () {
  let extent = Array(4);
  extent[0] = parseFloat($("#WLatLong").val());
  extent[1] = parseFloat($("#SLatLong").val());
  extent[2] = parseFloat($("#ELatLong").val());
  extent[3] = parseFloat($("#NLatLong").val());
  selectedBounds.setLatLongExtent(extent);
  selectedBounds.show();
};

// Update map on pressing enter
var checkLatLongForEnter = function (event) {
  if (event.key === "Enter") {
    setInputLatLongVals();
  }
};

$("#NLatLong").on("blur", setInputLatLongVals);
$("#SLatLong").on("blur", setInputLatLongVals);
$("#ELatLong").on("blur", setInputLatLongVals);
$("#WLatLong").on("blur", setInputLatLongVals);

$("#NLatLong").on("keydown", checkLatLongForEnter);
$("#SLatLong").on("keydown", checkLatLongForEnter);
$("#ELatLong").on("keydown", checkLatLongForEnter);
$("#WLatLong").on("keydown", checkLatLongForEnter);

// Enable full screen button
$(document).bind(
  "webkitfullscreenchange mozfullscreenchange fullscreenchange",
  function (e) {
    fullScreen =
      document.fullScreen ||
      document.mozFullScreen ||
      document.webkitIsFullScreen;
    if (fullScreen) {
      $('[class*="col-"]').css({
        height: "100%",
      });
    } else {
      $('[class*="col-"]').css({
        height: "96vh",
      });
    }
    fixSizeBug();
  }
);

// Allow the user to change the assessment type
function radioLayerUpdate() {
  let radioVal = $("input[name='layer']:checked").val();
  var overallBox = $(".overall-layer-options");
  var independentBox = $(".independent-layer-options");
  var outputBox = $(".output-layer-options");
  var esacBox = $(".esac-layer-options");
  overallBox.css({
    display: "none",
  });
  independentBox.css({
    display: "none",
  });
  outputBox.css({
    display: "none",
  });
  esacBox.css({
    display: "none",
  });
  if (radioVal == "tradeoff") {
    overallBox.css({
      display: "block",
    });
    window.displayMode = window.currentTradeoffChoice;
    updateTradeoffSelection(window.currentTradeoffChoice);
    updateLayer();
  }

  if (radioVal == "ind") {
    independentBox.css({
      display: "block",
    });
    window.currentLayer = window.independentLayer;
    window.displayMode = "single";
    updateLayer();
  }

  if (radioVal == "out") {
    outputBox.css({
      display: "block",
    });
    window.currentLayer = window.outLayer;
    window.displayMode = "single";
    updateLayer();
  }

  if (radioVal == "esac") {
    esacBox.css({
      display: "block",
    });
    window.currentLayer = window.esacLayer;
    window.displayMode = "single";
    updateLayer();
  }

  $("#input-accordion").accordion("refresh");
}

// Change the base layer
function radioBaseUpdate() {
  let radioVal = $("input[name='base']:checked").val();
  map.removeLayer(satLayer);
  map.removeLayer(streetLayer);
  map.removeLayer(topoLayer);
  if (radioVal == "sat") {
    map.getLayers().insertAt(0, satLayer);
  } else if (radioVal == "street") {
    map.getLayers().insertAt(0, streetLayer);
  } else if (radioVal == "topo") {
    map.getLayers().insertAt(0, topoLayer);
  }
}

// Calls functions on layer updates
$("input[type=radio][name=layer]").change(radioLayerUpdate);
$("input[type=radio][name=base]").change(radioBaseUpdate);

radioLayerUpdate();
radioBaseUpdate();

$(function () {
  var handle = $("#custom-handle");
  $("#slider").slider({
    min: 10,
    max: 90,
    step: 10,
    value: 50,
    create: function () {
      handle.text($(this).slider("value") + "%");
    },
    slide: function (event, ui) {
      handle.text(ui.value + "%");
    },
  });
});

// Define selection of individual map tiles, and set up popup
var selectStyle = function (feature) {
  var rank = getRankValue(feature);
  if (shouldShowFeature(feature)) {
    var color = colorRankInterpolate(rank);
    return new Style({
      fill: new Fill({
        color: color,
      }),
      stroke: new Stroke({
        color: [0, 0, 0],
        width: 2,
      }),
    });
  } else {
    return new Style({
      fill: null,
      stroke: null,
    });
  }
};

var select = new Select({
  style: selectStyle,
});
map.addInteraction(select);
select.on("select", function (e) {
  var features = e.target.getFeatures().getArray();
  if (features.length == 1 && shouldShowFeature(features[0])) {
    let feature = features[0];
    let extent = feature.getGeometry().getExtent();
    var featureValues = feature.getProperties();
    let center = getCenter(extent);
    let content = $("<p>");
    let lonLat = toLonLat(center);
    let lon = lonLat[0].toFixed(3);
    let lat = lonLat[1].toFixed(3);
    let code;
    if (window.displayMode == "stressors") {
      code = "rb";
    } else if (window.displayMode == "ExSenAd") {
      code = "ub";
    } else {
      code = window.currentLayer;
    }
    let idVal = feature.getId();
    content.append(
      "<span><b>Position:</b> " + lon + "°E, " + lat + "°N</span><br>"
    );
    content.append(
      "<span><b>Town:</b> " +
        window.metadata.towns[featureValues.t].nickname +
        "</span><br>"
    );
    content.append(
      "<span><b>COG:</b> " +
        window.metadata.cogs[featureValues.c].nickname +
        "</span><br>"
    );
    let url = "/api/v1/?cell=" + idVal + "&layerCode=" + code + "&callback=?";

    content.append(
      "<span><b>Rank:</b> " + getRankValue(feature) + "</span><br>"
    );

    $.ajax({
      url: url,
      dataType: "jsonp",
      jsonpCallback: "jsonp",
      success: function (data) {
        Object.keys(data).forEach(function (key) {
          content.append(
            "<span><b>" +
              key.capitalize() +
              ": </b> " +
              data[key] +
              "</span><br>"
          );
        });
        $("#popup-content").html(content);
        popupOverlay.setPosition(center);
      },
    });
  } else {
    closePopup();
  }
});

$("#popup-closer").click(closePopup);

// var tradeoffs = {
//   sensitivity: {
//     name: "Physical vs. Climate Exposure",
//     variables: ["exposure-physical", "exposure-climate"],
//     sliders: {
//       exposure_slider: {
//         left: "Physical Exposure",
//         right: "Climate Exposure",
//       },
//     },
//     callback: function (context) {
//       return (
//         context.exposure_slider * context["exposure-physical"] +
//         (1 - context.exposure_slider) * context["exposure-climate"]
//       );
//     },
//     description:
//       "This is the tradeoff between physical exposure and climate exposure. We need a better explanation here.",
//   },
// };

// Create tradeoff selection
var tradeoffPicker = $("#tradeoff-picker");
window.currentTradeoffContext = {};
for (var slider_key in tradeoffs) {
  tradeoffPicker.append(
    $("<option>").attr("value", slider_key).text(tradeoffs[slider_key].name)
  );
}
$(tradeoffPicker).combobox({
  label: "Select a tradeoff to explore.",
  select: function (event, ui) {
    updateTradeoffSelection(ui.item.value);
  },
});

window.currentTradeoffChoice = tradeoffPicker.children().first().val();

function updateTradeoffSelection(tradeoffVal) {
  let tradeoff_options_div = $("#tradeoff-option-group");
  tradeoff_options_div.empty();
  let tradeoff_specification = tradeoffs[tradeoffVal];
  window.currentTradeoffSpecification = tradeoff_specification;
  window.currentTradeoffLayerKeys = {};
  tradeoff_specification.variables.forEach(function (layerShortName) {
    for (let layerCode in window.metadata.layers) {
      // console.log(
      //   window.metadata.layers[layerCode].shortName,
      //   layerShortName,
      //   layerShortName == window.metadata.layers[layerCode].shortName
      // );
      if (layerShortName == window.metadata.layers[layerCode].shortName) {
        window.currentTradeoffLayerKeys[layerShortName] = layerCode;
      }
    }
  });

  for (slider_key in tradeoff_specification.sliders) {
    let slider_specification = tradeoff_specification.sliders[slider_key];
    let slider_div = $("<div>").attr("id", slider_key);
    tradeoff_options_div.append(slider_div);
    slider_div.prop_slider({
      left: slider_specification.left,
      right: slider_specification.right,
      callback: function (value) {
        window.currentTradeoffContext[slider_key] = value;
        updateLayer();
      },
    });
  }
}

// Add jQuery tabs for information panel on the right
window.layerStatsLoaded = false;
$("#tabs")
  .tabs()
  .on("tabsbeforeactivate", function (event, ui) {
    if (!window.layerStatsLoaded) {
      calculateActiveVectorProperties();
      displayLayer(window.currentLayer);
      window.layerStatsLoaded = true;
    }
  });

function setAccordion(itemNum) {
  if ($("#input-accordion").accordion("option", "active") != itemNum) {
    $("#input-accordion").accordion("option", "active", itemNum);
    // hacky way to force reflow after accordion animates
    setTimeout(function () {
      if (introjs_event.resize2_1 != null) {
        introjs_event.resize2_1();
      } else if (introjs_event.resize2_3 != null) {
        introjs_event.resize2_3();
      }
    }, 400);
  }
}

function startTour() {
  introJs()
    .setOptions({
      showStepNumbers: false,
      showProgress: true,
      exitOnOverlayClick: false,
      hidePrev: true,
      hideNext: true,
      showBullets: false,
      steps: [
        {
          intro: "Welcome to CIRCA's Coastal Vulnerability Viewer.",
        },
        {
          element: $("#input-accordion")[0],
          intro:
            "This is your control panel. You can change options here to explore the multidimensional impact of climate change.",
          position: "auto",
        },
        {
          element: $("#col-map")[0],
          intro:
            "This is a map viewport of coastal Connecticut. It will display the data you select in the control panel, with many viewing options. It also behaves similarly to Google Maps.",
          scrollTo: "tooltip",
        },
        {
          element: $("#tabs")[0],
          intro:
            "This panel contains dynamic information and statistics about the data you're viewing.",
        },
        {
          element: $("#interest-area-header")[0],
          intro:
            "The first thing you should do is select an area of interest. After doing so, the map will filter the data, update all statistics, and zoom to provide you with a better view.",
          position: "right",
        },
        {
          element: $("#interest-area-picker")[0],
          intro:
            "You can select the entire coastline, a town, a COG, or a custom bounding area from this dropdown box. You can also type the name for faster lookup.",
          position: "right",
        },
        {
          element: $("#layer-picker-header")[0],
          intro: "Next select the data you want to display.",
          position: "right",
        },
        {
          element: $("#layer-picker-form")[0],
          intro: "There are three main data types you can explore.",
          position: "right",
        },
        {
          element: $("#ind-option-span")[0],
          intro:
            "The first option presents the contributor data layers. They are the input layers ranked according to their vulnerability to the sea-level rise.",
          position: "right",
        },
        {
          element: $("#independent-layer-box")[0],
          intro:
            "You can pick which of these layers you'd like to see under this tab.",
          position: "right",
        },
        {
          element: $("#layer-exp-tab")[0],
          intro:
            "Each time you change any layer parameters, the layer explanation and statistics will update.",
          position: "left",
        },
        {
          intro:
            "You can also zoom in and click on individual grid cells to see all of their properties, which have been normalized on a 1 to 5 scale. 0s indicate no data. (This option is currently only available on Firefox.)",
        },
        {
          element: $("#out-option-span")[0],
          intro:
            "The second option presents precomputed vulnerability models that combine multiple contributors.",
          position: "right",
        },
        {
          element: $("#output-layer-box")[0],
          intro:
            "You can select one of the pre-made models from here. This dropdown works like the one before.",
          position: "right",
        },
        {
          element: $("#tradeoff-option-span")[0],
          intro:
            "Finally, you can explore dynamic models which allow you to consider tradeoffs and preferences.",
          position: "right",
        },
        {
          element: $("#tradeoff-option-group")[0],
          intro:
            "In addition to picking one of the models, you can move the slider to give more or less weight to different priorities.",
          position: "right",
        },
        {
          element: $("#base-header")[0],
          intro:
            "You can change the viewers base layer to satellite, street, or topographic maps.",
          position: "right",
        },
        {
          element: $("#view-options-body")[0],
          intro:
            "You can change other things about the viewer, including the map's color scheme and transparency.",
          position: "right",
        },
        {
          element: $("#sea-level-overlay-picker")[0],
          intro:
            "You can also overlay current an projected sea-level scenarios. These layers are from CIRCA sea-level rise viewer.",
          position: "right",
        },
        {
          element: $("#downloads-tab")[0],
          intro:
            "You can also download layer-specific GIS files and metadata here.",
          position: "left",
        },
        {
          intro:
            "Thank you for following along on this tour. We hope you find this tool useful.",
        },
      ],
    })
    .onafterchange(function (targetElement) {
      if (window.updatingGuide) {
        setTimeout(function () {
          console.log("Updating");
        }, 100);
        window.updatingGuide = false;
        return true;
      }
    })
    .onbeforechange(function (targetElement) {
      if (
        targetElement == $("#interest-area-picker")[0] ||
        targetElement == $("#interest-area-picker")[0]
      ) {
        setAccordion(0);
      } else if (
        targetElement == $("#layer-picker-header")[0] ||
        targetElement == $("#layer-picker-form")[0]
      ) {
        setAccordion(1);
      } else if (targetElement == $("#ind-option-span")[0]) {
        setAccordion(1);
        $("input[name='layer']:nth(0)").attr("checked", true);
        radioLayerUpdate();
      } else if (
        targetElement == $("#ind-layer-header")[0] ||
        targetElement == $("#independent-layer-box")[0]
      ) {
        setAccordion(2);
      } else if (targetElement == $("#out-option-span")[0]) {
        setAccordion(1);
        $("input[name='layer']:nth(1)").attr("checked", true);
        radioLayerUpdate();
      } else if (targetElement == $("#output-layer-box")[0]) {
        setAccordion(3);
      } else if (targetElement == $("#tradeoff-option-span")[0]) {
        setAccordion(1);
        $("input[name='layer']:nth(2)").attr("checked", true);
        radioLayerUpdate();
      } else if (targetElement == $("#tradeoff-option-group")[0]) {
        setAccordion(4);
      } else if (targetElement == $("#base-header")[0]) {
        setAccordion(5);
      } else if (
        targetElement == $("#view-options-body")[0] ||
        targetElement == $("#sea-level-overlay-picker")[0]
      ) {
        setAccordion(6);
      } else if (targetElement == $("#layer-exp-tab")[0]) {
        setAccordion(1);
        $("#tabs").tabs("option", "active", 1);
        setTimeout(function () {
          $("input[name='layer']:nth(1)").attr("checked", true);
          radioLayerUpdate();
        }, 4000);
      } else if (targetElement == $("#downloads-tab")[0]) {
        setAccordion(1);
        $("#tabs").tabs("option", "active", 3);
      }
    })
    .start();
}

$("#tour-button").click(startTour);
