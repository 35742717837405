import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";

export const CONTACTLINK =
  "https://visitor.r20.constantcontact.com/manage/optin?v=001rjHrKBojjikb41IyNcK_VDuGA85PsYunVFxwTdzNf2JKVoVE5hX3-dQe4UNVqS9P8Gu0JynHkxjgforptFZPjuuEoyy4wOz_qrQuBPXYto0%3D";

export var tradeoffs = {
  exposure: {
    name: "Physical vs. Climate Exposure",
    variables: ["exposure-physical", "exposure-climate"],
    sliders: {
      exposure_slider: {
        left: "Physical Exposure",
        right: "Climate Exposure",
      },
    },
    callback: function (context) {
      return (
        context.exposure_slider * context["exposure-physical"] +
        (1 - context.exposure_slider) * context["exposure-climate"]
      );
    },
    description:
      "This is the tradeoff between physical exposure and climate exposure. Physical exposure indicates the non-climate related environmental contributors that will increase the potential impact of climate vulnerability in the region. The contributor layers defined for physical exposure are erosion susceptibility, saltwater limit, elevation, soil flooding and shoreline change rate. Climate exposure indicates the climate related contributors that will increase the potential impact of climate vulnerability in the region by changing the extreme event magnitude and frequency. The contributor layers defined for climate exposure are sea level rise, storm surge, tidal range, wave power and wind at 10 m above mean sea level. Please move the slider to see how the changing the weight of one variable effects the overall exposure in the area.",
  },
  sensitivity: {
    name: "Built, Socio-Economic and Ecological Sensitivity",
    variables: ["sensitivity-built", "sensitivity-ecological", "sensitivity-socioeconomic"],
    sliders: {
      Sensitivity_built_slider: {
        left: "Built Sensitivity",
        right: "",
      },
      Sensitivity_ecological_slider: {
        left: "Ecological Sensitivity",
        right: "",
      },
      Sensitivity_socioEconomic_slider: {
        left: "Socio-Economic Sensitivity",
        right: "",
      },
    },
    callback: function (context) {
      return (
       context.Sensitivity_built_slider * context["sensitivity-built"] + 
         context.Sensitivity_ecological_slider * context["sensitivity-ecological"] +
         context.Sensitivity_socioEconomic_slider * context["sensitivity-socioeconomic"]
      );
    },
    description:
      "This is the tradeoff between built, socio-economic and ecological sensitivity. Built sensitivity represents likelihood of changes in built human systems. The contributor layers of built sensitivity are railways, cables, streets, buildings, critical infrastructure. Socio-economic sensitivity represents likelihood of changes in solely human systems. The contributor layers of socio-economic sensitivity are disable population, employment rate, median household income, health, young population, elderly population, population density, literacy rate, non-English speakers, single parent household, multi-unit household, mobile homes, absence of vehicles, quarter living homes. Ecological sensitivity represents likelihood of changes in solely natural conditions. The contributor layers of ecological sensitivity are critical habitat, aquifer protection areas, hydrology connectivity, and impervious area. Please move the slider to see how the changing the weight of one variable effects the overall sensitivity in the area.",
  },
  adaptiveCapacity: {
    name: "Built vs. Ecological Adaptive Capacity",
    variables: ["ac-built", "ac-ecological"],
    sliders: {
      adaptiveCapacity_slider: {
        left: "Built Adaptive Capacity",
        right: "Ecological Adaptive Capacity",
      },
    },
    callback: function (context) {
      return (
        context.adaptiveCapacity_slider * context["ac-built"] +
        (1 - context.adaptiveCapacity_slider) * context["ac-ecological"]
      );
    },
    description:
      "This is the tradeoff between built and ecological adaptive capacity. Built adaptive capacity represents the built environment that helps the reduce the impact of the climate change. The contributor layers for built adaptive capacity are coastal structures, proximity to I95 exists, distance to healthcare centers and, distance to shelters. Ecological adaptive capacity represents the natural environment that helps the reduce the impact of the climate change. The contributor layers for ecological adaptive capacity are marsh mitigation extend by 2055, distance to rivers, natural habitat, and land form. Please move the slider to see how the changing the weight of one variable effects the overall adaptive capacity in the area.",
  },
  esac: {
    name: "Exposure, Sensitivity, Adaptive Capacity",
    variables: ["Exposure", "Sensitivity", "AdaptiveCapacity"],
    sliders: {
      exp_slider: {
        left: "Exposure",
        right: "",
      },
      sens_slider: {
        left: "Sensitivity",
        right: "",
      },
      adaptivity_slider: {
        left: "Adaptive Capacity",
        right: "",
      },
    },
    callback: function (context) {

      var finalVal = (
          (context.exp_slider * context["Exposure"] ) + ( context.sens_slider * context["Sensitivity"] ) + ( context.adaptivity_slider * context["AdaptiveCapacity"])
      ) ;

      console.log(finalVal);
      return finalVal;
    },
    description:
      "This is the tradeoff between Exposure, Sensitivity and Adaptive Capacity. Exposure is the degree of the stress that the particular asset is going through with climate variability. Sensitivity is the degree to which a built, natural, or human system will be impacted by changes in climate conditions. Adaptive Capacity is the ability of a system to adjust to changes and cope with consequences. Please move the slider to see how the changing the weight of one variable effects the overall coastal vulnerability in the area.",
  },

};



export var hey = "aa"

export var scheme1 = {
  name: "Scheme 1",
  code: "a",
  one: [56, 168, 0],
  two: [139, 209, 0],
  three: [255, 255, 0],
  four: [255, 170, 0],
  five: [255, 0, 0],
};

export var scheme2 = {
  name: "Scheme 2",
  code: "b",
  one: [100, 243, 255],
  two: [120, 94, 240],
  three: [220, 138, 127],
  four: [254, 97, 0],
  five: [255, 176, 0],
};

export var scheme3 = {
  name: "Scheme 3",
  code: "c",
  one: [241, 238, 246],
  two: [215, 181, 216],
  three: [223, 101, 176],
  four: [221, 28, 119],
  five: [152, 0, 67],
};

export var streetLayer = new TileLayer({
  source: new XYZ({
    attributions:
      'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/' +
      'rest/services/World_Street_Map/MapServer">ArcGIS</a>',
    url:
      "https://server.arcgisonline.com/ArcGIS/rest/services/" +
      "World_Street_Map/MapServer/tile/{z}/{y}/{x}",
  }),
});

export var topoLayer = new TileLayer({
  source: new XYZ({
    attributions:
      'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/' +
      'rest/services/World_Topo_Map/MapServer">ArcGIS</a>',
    url:
      "https://server.arcgisonline.com/ArcGIS/rest/services/" +
      "World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
  }),
});

export var satLayer = new TileLayer({
  source: new XYZ({
    attributions:
      'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/' +
      'rest/services/World_Imagery/MapServer">ArcGIS</a>',
    url:
      "https://server.arcgisonline.com/ArcGIS/rest/services/" +
      "World_Imagery/MapServer/tile/{z}/{y}/{x}",
  }),
});
